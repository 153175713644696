/* eslint-disable no-param-reassign */ // <-- disabled for animating directly on object references
import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { IconButton, Popover, Box, Slider, Typography } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  animationResetThreshold,
  waveComponent1VertexScaler,
  waveComponent1FrequencyMultiplier,
  waveComponent2VertexScaler,
  waveComponent2FrequencyMultiplier,
  waveDefaultFrequency,
  waveDefaultAmplitude,
  cameraInitialYRotation,
  cameraInitialXLookDownAngle,
  cameraRotation,
  cameraMinFOV,
  cameraMaxFOV,
  cameraMinScreenWidthBound,
  cameraMaxScreenWidthBound,
  cameraNear,
  cameraFar,
  cameraX,
  cameraY,
  cameraZ,
  planeDefinition,
  planeSize,
  meshColor,
  fogColor,
  fogNear,
  fogFar,
  rendererColor,
  rendererPosition,
  rendererTop,
  rendererZIndex,
  rendererWidth,
  rendererHeight,
} from "../../data/wave/control";

function animateWave(
  scene,
  planeGeo,
  camera,
  renderer,
  frequencyRef,
  amplitudeRef,
) {
  let count = 0;

  const calculateWaveFrame = () => {
    // calculateWaveFrame is a callback for every repaint of the browser
    // this creates a loop with the frequency of calls matching
    // the display refresh rate of the browser, giving a smooth animation
    requestAnimationFrame(calculateWaveFrame);

    // rotate camera and normalize rotation to 0 to 2π radians
    camera.rotation.y += cameraRotation;
    camera.rotation.y %= 2 * Math.PI;

    // calculate the next position of a point in the wave
    // the positions of the geometry are stored in a flattened array of coordinates
    // each vertex has 3 (x, y, and z) components, so divide by 3 to get the size
    const positions = planeGeo.attributes.position.array;
    const length = positions.length / 3;
    for (let i = 0; i < length; i += 1) {
      const px = positions[i * 3];
      const pz = positions[i * 3 + 2];
      const index = i * 3 + 1;
      positions[index] =
        Math.sin(
          (px / waveComponent1VertexScaler + count) *
            waveComponent1FrequencyMultiplier,
        ) *
          Math.cos(
            (pz / waveComponent1VertexScaler + count) *
              waveComponent1FrequencyMultiplier,
          ) *
          amplitudeRef.current -
        Math.sin(
          (px / waveComponent2VertexScaler - count) *
            waveComponent2FrequencyMultiplier,
        ) *
          Math.cos(
            (pz / waveComponent2VertexScaler - count) *
              waveComponent2FrequencyMultiplier,
          ) *
          amplitudeRef.current;
    }

    planeGeo.attributes.position.needsUpdate = true;
    count = count > animationResetThreshold ? 0 : count + frequencyRef.current;
    renderer.render(scene, camera);
  };

  calculateWaveFrame();
}

function calculateFov() {
  // adjust camera fov dynamically for optimal viewing experience
  const scaleFactor =
    (cameraMaxScreenWidthBound - window.innerWidth) /
    (cameraMaxScreenWidthBound - cameraMinScreenWidthBound);
  const fov = cameraMinFOV + (cameraMaxFOV - cameraMinFOV) * scaleFactor;
  return Math.max(cameraMinFOV, Math.min(fov, cameraMaxFOV));
}

function waveComponent() {
  // reference the box where we do the wave render
  const mountRef = useRef(null);

  // set the state of elements
  const [frequency, setFrequency] = useState(waveDefaultFrequency);
  const [amplitude, setAmplitude] = useState(waveDefaultAmplitude);
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverOpen = Boolean(anchorEl);

  // use references to state variables because the animation of the wave render
  // forms a closure over those state variables
  // that is, there are two separate renders going on:
  // a wave being rendered continously by THREE.JS, and the React DOM
  // for performance, we only re-render the DOM when we detect a user event
  // the rest of the time, the THREE.JS render just uses a refernce to the state
  // to avoid triggering continuous DOM re-renders
  const tickRef = useRef(frequency);
  const amplitudeRef = useRef(amplitude);
  useEffect(() => {
    tickRef.current = frequency;
  }, [frequency]);
  useEffect(() => {
    amplitudeRef.current = amplitude;
  }, [amplitude]);

  // event handlers
  const handleAmplitudeChange = (event, newValue) => setAmplitude(newValue);
  const handleFrequencyChange = (event, newValue) => setFrequency(newValue);
  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  useEffect(() => {
    // camera setup
    const camera = new THREE.PerspectiveCamera(
      calculateFov(),
      window.innerWidth / window.innerHeight,
      cameraNear,
      cameraFar,
    );
    camera.position.set(cameraX, cameraY, cameraZ);

    camera.rotation.order = "YXZ";
    camera.rotation.y = cameraInitialYRotation;
    camera.rotateX(cameraInitialXLookDownAngle * (Math.PI / 180));

    // geometry is the structure that defines the shape of the
    // object in 3D space, containing all vertices and faces
    const planeGeo = new THREE.PlaneGeometry(
      planeSize,
      planeSize,
      planeDefinition,
      planeDefinition,
    );

    // material defines the visual appearence of the geometry surface
    // if wireframe were false, the faces of the geometry would be filled in
    const planeMaterial = new THREE.MeshBasicMaterial({
      color: meshColor,
      wireframe: true,
    });

    // mesh uses the form defined by the geometry and applies the material,
    // resulting in a visible object in 3D space
    const plane = new THREE.Mesh(planeGeo, planeMaterial);

    // planes are aligned on the XY-axis by default, so do a 90 degree rotation
    // this allows our plane to be aligned on the XZ-axis, like waves in real-life!
    // note: using a function like plane.rotation.x will NOT update the vertex
    //       data of the geometry, resulting in the z-axis of the plane in local space
    //       becoming the y-axis relative to the world space
    //       we must ensure that the local coordinates of the geometry are also
    //       rotated to match the mesh's rotation
    plane.geometry.rotateX(Math.PI * 0.5);

    // scene setup
    const scene = new THREE.Scene();
    scene.fog = new THREE.Fog(fogColor, fogNear, fogFar);
    scene.add(plane);

    // renderer setup
    const renderer = new THREE.WebGLRenderer({
      alpha: false,
      antialias: true,
    });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio); // improves resolution for high-pixel density displays
    renderer.setClearColor(rendererColor, 1);

    // window positioning and mounting
    const rendererDom = renderer.domElement;
    rendererDom.style.position = rendererPosition;
    rendererDom.style.top = rendererTop;
    rendererDom.style.zIndex = rendererZIndex;
    rendererDom.style.width = rendererWidth;
    rendererDom.style.height = rendererHeight;

    // adjust camera fov and renderer size on window resize
    const handleResize = () => {
      camera.aspect =
        document.documentElement.clientWidth /
        document.documentElement.clientHeight;
      camera.fov = calculateFov();
      camera.updateProjectionMatrix();
      renderer.setSize(
        document.documentElement.clientWidth,
        document.documentElement.clientHeight,
      );
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // mount the rendered scene to the box
    mountRef.current.innerHTML = "";
    mountRef.current.appendChild(renderer.domElement);

    // finally, kick off the animation!
    animateWave(scene, planeGeo, camera, renderer, tickRef, amplitudeRef);

    // cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box>
      <Box ref={mountRef} />
      <IconButton
        color="primary"
        aria-label="settings"
        onClick={handlePopoverOpen}
        sx={{
          position: "absolute",
          bottom: "30px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: "999",
          display: "flex",
          alignItems: "center",
          color: "black",
          backgroundColor: "lightGray",
          transition: "background-color 0.25s ease-in-out",
          "&:hover": {
            backgroundColor: "gray",
          },
        }}
      >
        <SettingsIcon />
      </IconButton>
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          ".MuiPaper-root": {
            background: "rgba(0, 0, 0, 0.8)",
            padding: "10px",
          },
        }}
      >
        {/* Frequency Slider */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "white",
            mb: 1,
          }}
        >
          <Typography
            sx={{
              mr: 1,
              fontFamily: "Montserrat",
              fontWeight: "300",
              fontSize: "1rem",
            }}
          >
            Frequency
          </Typography>
          <Slider
            size="small"
            min={0}
            max={0.01}
            step={0.0001}
            value={frequency}
            onChange={handleFrequencyChange}
            sx={{ color: "grey", width: 200 }}
          />
        </Box>
        {/* Amplitude Slider */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "white",
          }}
        >
          <Typography
            sx={{
              mr: 1,
              fontFamily: "Montserrat",
              fontWeight: "300",
              fontSize: "1rem",
            }}
          >
            Amplitude
          </Typography>
          <Slider
            size="small"
            min={0}
            max={10000}
            step={100}
            value={amplitude}
            onChange={handleAmplitudeChange}
            sx={{ color: "grey", width: 200 }}
          />
        </Box>
      </Popover>
    </Box>
  );
}

export default waveComponent;
