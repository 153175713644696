import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineCard from "../components/experience/TimelineCard";
import experienceTimelineCards from "../data/experience/experienceCards";
import LoadInFadeStyle from "../styles/LoadInFadeStyle";
import theme from "../styles/theme";

export default function Experience() {
  // Page Fade State
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);
  const PageFadeIn = LoadInFadeStyle(loaded);

  // Timeline Format State
  const [timelineType, setPosition] = useState(
    window.innerWidth > 600 ? "alternate" : "right",
  );
  useEffect(() => {
    const handleResize = () => {
      setPosition(window.innerWidth > 600 ? "alternate" : "right");
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box
      sx={{
        ...PageFadeIn,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: `calc(100vw - ${theme.scrollBar.width})`,
      }}
    >
      <Timeline
        position={timelineType}
        sx={{
          maxWidth: "800px",
          minWidth: "200px",
        }}
      >
        {experienceTimelineCards.map((card, index) => {
          // in order to align the card content correctly,
          // we pass in a prop telling each card its position
          // relative to the timeline stem
          let relativeCardPosition;
          if (timelineType === "alternate") {
            relativeCardPosition = index % 2 === 0 ? "right" : "left";
          } else {
            relativeCardPosition = "right";
          }

          return (
            <TimelineCard
              key={card.id}
              position={relativeCardPosition}
              timelineType={timelineType}
              itemDetails={card}
            />
          );
        })}
      </Timeline>
    </Box>
  );
}
