import React, { useState, useEffect, useRef } from "react";
import { Box, CircularProgress, Tooltip } from "@mui/material";

export default function GithubContributions() {
  const [imageLoaded, setImageLoaded] = useState(false); // Track image loading state
  const scrollContainerRef = useRef(null); // Create a ref for the scrollable container

  useEffect(() => {
    // Check if the image is loaded and the container reference is available
    if (imageLoaded && scrollContainerRef.current) {
      // Scroll to the rightmost position
      const { scrollWidth } = scrollContainerRef.current;
      scrollContainerRef.current.scrollLeft = scrollWidth;
    }
  }, [imageLoaded]); // Depend on the imageLoaded state

  return (
    <Box
      ref={scrollContainerRef} // Attach the ref to the Box
      sx={{
        overflowX: "auto", // Enable horizontal scrolling
        maxWidth: "100%", // Limit the container width
        height: "200px", // Fixed height for the container
        display: "flex",
        alignItems: "center",
        justifyContent: imageLoaded ? "flex-start" : "center", // Center spinner, align image to the left
        "&::-webkit-scrollbar": {
          height: "4px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "gray",
          borderRadius: "2px",
        },
        "& scrollbarWidth": "thin",
        "& scrollbarColor": "gray transparent",
      }}
    >
      <CircularProgress
        sx={{
          color: "white",
          position: "absolute",
          display: imageLoaded ? "none" : "flex",
        }}
      />
      <Tooltip
        placement="bottom"
        title={
          <>
            Github Commit Frequency
            <br />
            The shade of green scales darker with the number of commits
          </>
        }
      >
        <Box
          component="img"
          src="https://ghchart.rshah.org/228B22/d-x-s"
          alt="Davis Song's GitHub Contributions"
          onLoad={() => setImageLoaded(true)} // Set imageLoaded to true when the image finishes loading
          sx={{
            // display: imageLoaded ? "block" : "none", // Only display the image once it's loaded
            minWidth: "750px",
            width: "100vw",
            maxWidth: "1000px",
            height: "auto",
            opacity: imageLoaded ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
          }}
        />
      </Tooltip>
    </Box>
  );
}
