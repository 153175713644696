export const ResponsivePage = (theme) => ({
  width: "100%",

  [theme.breakpoints.up("xs")]: {
    // extra-small: 0px
    width: "85%",
  },
  [theme.breakpoints.up("sm")]: {
    // small: 600px
    width: "80%",
  },
  [theme.breakpoints.up("md")]: {
    // medium: 900px
    width: "70%",
  },
  [theme.breakpoints.up("lg")]: {
    // large: 1200px
    width: "60%",
  },
  [theme.breakpoints.up("xl")]: {
    // extra-large: 1536px
    width: "50%",
  },
});

export default ResponsivePage;
