import React, { useState } from "react";
import {
  Typography,
  Avatar,
  Box,
  Collapse,
  Link,
  Tooltip,
} from "@mui/material";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import IconButton from "@mui/material/IconButton";
import TechnologyLogosBox from "./TechnologyLogosBox";

function TimelineCard({ timelineType, position, itemDetails }) {
  const isRightAlignTimeline = timelineType === "right";
  const boxAlignment = position === "right" ? "flex-start" : "flex-end";

  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = (event) => {
    if (!event.target.closest("a")) {
      setExpanded(!expanded);
    }
  };

  return (
    <TimelineItem sx={{ color: "white" }} onClick={handleExpandClick}>
      {/* DATE/OPPOSITE CONTENT AREA */}
      {itemDetails.date && (
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          align="right"
          color="white"
          display={isRightAlignTimeline ? "none" : "block"}
        >
          <Typography variant="body1">{itemDetails.date}</Typography>
        </TimelineOppositeContent>
      )}
      <TimelineSeparator
        sx={isRightAlignTimeline ? { paddingLeft: "4vw" } : {}}
      >
        {/* COMPANY LOGO */}
        <TimelineConnector />
        {itemDetails.companyLogo && (
          <Tooltip title={itemDetails.companyLink || "#"}>
            <Link
              href={itemDetails.companyLink || "#"}
              sx={{
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.2)",
                  transformOrigin: "center",
                },
              }}
            >
              <TimelineDot
                sx={{
                  backgroundColor: "white",
                  width: 60,
                  height: 60,
                  padding: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0px",
                }}
              >
                <Avatar
                  src={itemDetails.companyLogo}
                  alt={itemDetails.companyName}
                  sx={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    borderRadius: "0px",
                  }}
                />
              </TimelineDot>
            </Link>
          </Tooltip>
        )}
        <TimelineConnector />
      </TimelineSeparator>
      {/* TITLE/LOCATION/DESCRIPTION/CONTENT AREA */}
      <TimelineContent sx={{ color: "white", py: "12px", px: 2 }}>
        {itemDetails.companyName && (
          <Link
            href={itemDetails.companyLink}
            color="inherit"
            underline="hover"
          >
            <Typography
              variant="h6"
              component="span"
              sx={{ lineHeight: "1.2" }}
            >
              {itemDetails.companyName}
            </Typography>
          </Link>
        )}
        {itemDetails.role && (
          <Typography sx={{ paddingTop: "0.5vh" }} variant="body1">
            {itemDetails.role}
          </Typography>
        )}
        {itemDetails.location && (
          <Typography variant="body1">{itemDetails.location}</Typography>
        )}
        {itemDetails.date && isRightAlignTimeline && (
          <Typography variant="body1">{itemDetails.date}</Typography>
        )}
        {itemDetails.logos && itemDetails.logos.length > 0 && (
          <TechnologyLogosBox
            logos={itemDetails.logos}
            boxAlignment={boxAlignment}
          />
        )}
        {/* COLLAPSIBLE DETAILS */}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={{ mt: 2 }}>
            {itemDetails.details && (
              <Typography
                paragraph
                variant="body2"
                sx={{ whiteSpace: "pre-wrap", textAlign: "left" }}
              >
                {itemDetails.details}
              </Typography>
            )}
          </Box>
        </Collapse>
        <IconButton
          onClick={handleExpandClick}
          sx={{
            padding: "0",
            marginLeft: "-4px",
            color: "white",
            transition: "transform 0.2s",
          }}
          size="small"
        >
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </TimelineContent>
    </TimelineItem>
  );
}

export default TimelineCard;
