import React from "react";
import { Box, Link, Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
import theme from "../../styles/theme";

function TechnologyLogosBox({ logos, boxAlignment }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
        justifyContent: boxAlignment,
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
    >
      {logos.map((logo) => (
        <Tooltip title={logo.link || "#"} key={logo.link}>
          <Link
            href={logo.link || "#"}
            sx={{
              display: "inline-flex",
              overflow: "hidden",
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.2)",
              },
            }}
          >
            <Icon
              fontSize="clamp(32px, 2.5vw, 64px)"
              icon={logo.name}
              alt={logo.alt}
              style={
                logo.color
                  ? { color: logo.color }
                  : { color: theme.palette.primary.light }
              }
            />
          </Link>
        </Tooltip>
      ))}
    </Box>
  );
}

export default TechnologyLogosBox;
