import { Outlet, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import Navbar from "./components/nav/Navbar";
import theme from "./styles/theme";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.dark,

          // stretch outtermost container to fit display
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Navbar />
        <Outlet />
      </Box>
    </ThemeProvider>
  );
}

export default App;
