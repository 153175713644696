import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import blogEntries from "../data/blog/blogEntries";
import BlogEntry from "../components/blog/BlogEntry";
import ResponsivePageStyle from "../styles/ResponsivePageStyle";
import theme from "../styles/theme";
import LoadInFadeStyle from "../styles/LoadInFadeStyle";
import PageContainerStyle from "../styles/PageContainerStyle";

const BlogContentContainerStyle = {
  paddingBottom: "50px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
};

export default function Blog() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const PageFadeIn = LoadInFadeStyle(loaded);

  return (
    <Box sx={{ ...PageFadeIn, ...PageContainerStyle }}>
      <Box sx={{ ...BlogContentContainerStyle, ...ResponsivePageStyle(theme) }}>
        {blogEntries.map((entry) => (
          <BlogEntry key={entry.id} entry={entry} />
        ))}
      </Box>
    </Box>
  );
}
