import RefryImage from "../../assets/projects/refry.png";
import MosaicImage from "../../assets/projects/mosaic.jpg";
import VacBaseImage from "../../assets/projects/vacbase.png";
import AironImage from "../../assets/projects/airon.png";
import RacketImage from "../../assets/projects/racket.png";
import PortfolioImage from "../../assets/projects/portfolio.png";
import RayTracerImage from "../../assets/projects/raytracer.jpg";
import SlackBotIamge from "../../assets/projects/slackbot.png";

const softwareProjects = [
  {
    title: "AI Slack Assistant",
    description: "Semantic search for your channel.",
    link: "https://github.com/d-x-s/ai-slack-bot",
    projectImage: SlackBotIamge,
    techStack: "Python, OpenAI, AWS OpenSearch",
  },
  {
    title: "AIRON Fitness",
    description: "AI-powered personal trainer.",
    link: "https://github.com/d-x-s/airon-fitness",
    projectImage: AironImage,
    techStack: "OpenAI, React.js, Express.js",
  },
  {
    title: "Personal Website",
    description: "You're looking right at it.",
    link: "https://dsong.dev",
    projectImage: PortfolioImage,
    techStack: "React.js, Material UI",
  },
  {
    title: "Ray Tracer",
    description: "Realistic light simulation.",
    link: "https://d-x-s.github.io/ray-tracer/",
    projectImage: RayTracerImage,
    techStack: "Javascript, Three.JS, OpenGL",
  },
  {
    title: "Racket-ish Compiler",
    description: "From custom language to x86.",
    link: "https://github.com/d-x-s/x86-compiler",
    projectImage: RacketImage,
    techStack: "Racket",
  },
  {
    title: "Mosaic Generator",
    description: "Recursive image generator.",
    link: "https://github.com/d-x-s/photo-mosaic-generator/tree/main",
    projectImage: MosaicImage,
    techStack: "C++",
  },
  {
    title: "VacBase",
    description: "COVID vaccine database.",
    link: "https://github.com/d-x-s/vacbase",
    projectImage: VacBaseImage,
    techStack: "SQL, Java",
  },
  {
    title: "REFRY",
    description: "Food delivery alternative.",
    link: "https://github.com/d-x-s/REFRY",
    projectImage: RefryImage,
    techStack: "React.js",
  },
];

export default softwareProjects;
