// Default style for the outtermost content container
// This container should stretch to fit the entire horizontal screen width
// It will also stretch vertically based on the content inside
export const PageContainer = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

export default PageContainer;
