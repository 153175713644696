import AmazonLogo from "../../assets/experience/amazon.png";
import TegusLogo from "../../assets/experience/tegus.png";
import UBCLogo from "../../assets/experience/ubc.png";
import CopperleafLogo from "../../assets/experience/copperleaf.png";

const experienceCards = [
  {
    id: 3,
    date: "2024 May",
    companyName: "Amazon",
    companyLink: "https://www.aboutamazon.ca/",
    companyLogo: AmazonLogo,
    role: "Software Development Engineer Intern",
    location: "Vancouver, Canada",
    details: "AWS SQS (Simple Queue Service), dead-letter queues.",
    logos: [
      {
        name: "fa6-brands:aws",
        alt: "Amazon Web Services Icon",
        link: "https://aws.amazon.com/",
      },
      {
        name: "simple-icons:amazonsqs",
        alt: "Amazon SQS Icon",
        link: "https://aws.amazon.com/sqs/",
      },
      {
        name: "vscode-icons:file-type-kotlin",
        alt: "Kotlin Icon",
        link: "https://kotlinlang.org/",
      },
    ],
  },
  {
    id: 2,
    date: "2023 September",
    companyName: "Tegus",
    companyLink: "https://www.tegus.com/",
    companyLogo: TegusLogo,
    role: "Software Engineer Intern",
    location: "Vancouver, Canada",
    details:
      "Developed software used by equity research analysts to create bespoke financial models leveraged by financial firms in Wall Street and beyond. Primarily programming with C#, NET, AWS, and Python.\n\n" +
      "I took on significant ownership within analyst tooling to streamline critical parts of the financial modeling workflow from build to upload. My exploratory work with AI (LLMs and embeddings) resulted in a bot which empowered engineers and analysts by leveraging a rich dataset of support messages to assist in troubleshooting.",
    logos: [
      {
        name: "logos:python",
        alt: "Python Icon",
        link: "https://www.python.org/",
      },
      {
        name: "devicon:csharp",
        alt: "C# Icon",
        link: "https://learn.microsoft.com/en-us/dotnet/csharp/",
      },
      {
        name: "devicon-plain:dot-net-wordmark",
        alt: "Microsoft .NET Icon",
        link: "https://dotnet.microsoft.com/",
        color: "#bcaafa",
      },
      {
        name: "fa6-brands:aws",
        alt: "Amazon Web Services Icon",
        link: "https://aws.amazon.com/",
      },
      {
        name: "simple-icons:openai",
        alt: "OpenAI Icon",
        link: "https://openai.com/",
        color: "#21cca2",
      },
    ],
  },
  {
    id: 1,
    date: "2021 September",
    companyName: "Copperleaf",
    companyLink: "https://www.copperleaf.com/",
    companyLogo: CopperleafLogo,
    role: "Software Developer Intern",
    location: "Vancouver, Canada",
    details:
      "Joined Copperleaf's frontend modernization squad responsible for breathing new life into the look and feel of client-facing pages + dashboards.\n\n" +
      "I contributed Angular components to the internal design library and eliminated the need for manual regression testing by transitioning processes to a Selenium-automated test suite.",
    logos: [
      {
        name: "devicon:csharp",
        alt: "C# Icon",
        link: "https://www.python.org/",
      },
      {
        name: "vscode-icons:file-type-html",
        alt: "HTML Icon",
        link: "https://en.wikipedia.org/wiki/HTML",
      },
      {
        name: "vscode-icons:file-type-angular",
        alt: "Angular Icon",
        link: "https://angular.io/",
      },
      {
        name: "vscode-icons:file-type-scss",
        alt: "Sass Icon",
        link: "https://sass-lang.com/",
      },
      {
        name: "skill-icons:selenium",
        alt: "Selenium Icon",
        link: "https://www.selenium.dev/",
      },
    ],
  },
  {
    id: 0,
    date: "2019 September (Start)",
    companyName: "The University of British Columbia",
    companyLink: "https://www.ubc.ca/",
    companyLogo: UBCLogo,
    role: "Student | Bachelor of Computer Science",
    location: "Vancouver, Canada",
    details:
      "Teaching Assistant:\n" +
      "CPSC 455: Applied Industry Practices\n" +
      "\n" +
      "Relevant Coursework:\n" +
      "CPSC 455: Applied Industry Practices\n" +
      "CPSC 411: Compiler Construction\n" +
      "CPSC 322: Artificial Intelligence\n" +
      "CPSC 320: Algorithm Design and Analysis\n" +
      "CPSC 314: Computer Graphics\n" +
      "CPSC 313: Computer Hardware and OS\n" +
      "CPSC 310: Software Engineering\n" +
      "CPSC 304: Relational Databases\n" +
      "CPSC 221: Algorithms and Data Structures\n" +
      "CPSC 213: Computer Systems\n" +
      "CPSC 210: Software Construction\n" +
      "CPSC 121: Models of Computation\n" +
      "CPSC 110: Systematic Program Design\n",
    logos: [
      {
        name: "vscode-icons:file-type-racket",
        alt: "Racket Language Icon",
        link: "https://racket-lang.org/",
      },
      {
        name: "devicon:java",
        alt: "Java Language Icon",
        link: "https://www.java.com/",
      },
      {
        name: "devicon:cplusplus",
        alt: "C++ Language Icon",
        link: "https://cplusplus.com/",
      },
      {
        name: "devicon:matlab",
        alt: "Matlab Icon",
        link: "https://matlab.mathworks.com/",
      },
      {
        name: "vscode-icons:file-type-typescriptdef-official",
        alt: "Selenium Icon",
        link: "https://www.typescriptlang.org/",
      },
    ],
  },
];

export default experienceCards;
