import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
      dark: "#000000",
      light: "#ffffff",
      contrastText: "#ffffff",
    },
    error: {
      main: "#f44336",
    },
    warning: {
      main: "#ff9800",
    },
    info: {
      main: "#2196f3",
    },
    success: {
      main: "#4caf50",
    },
    background: {
      default: "#fff",
      paper: "#f4f6f8",
    },
  },
  typography: {
    fontFamily: "Montserrat, Arial, sans-serif",
    fontSize: 14,
    h1: {
      fontSize: "2.5rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h6: {
      fontSize: "1.4rem",
    },
    body1: {
      fontSize: "0.9rem",
    },
    body2: {
      fontSize: "0.75rem", // Example size, change as needed
      // fontWeight: 400, // Normal weight
      // lineHeight: 1.43, // Example line height, adjust as needed
      // letterSpacing: "0.01071em", // Example letter spacing, adjust as needed
    },
  },
  scrollBar: {
    width: "12px", // corresponds to width set in index.css
  },
  icon: {
    github: {
      hover: {
        color: "#C3B1E1",
      },
    },
    linkedin: {
      hover: {
        color: "#A7C7E7",
      },
    },
    gmail: {
      hover: {
        color: "#FF6961",
      },
    },
  },
  page: {
    transition: "opacity 0.5s ease-in-out",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default theme;
