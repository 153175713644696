export const animationResetThreshold = 100;

export const waveComponent1VertexScaler = 1000;
export const waveComponent1FrequencyMultiplier = 5;
export const waveComponent2VertexScaler = 500;
export const waveComponent2FrequencyMultiplier = 5;

export const waveDefaultFrequency = 0.001;
export const waveDefaultAmplitude = 3000;

export const cameraInitialYRotation = 0.1;
export const cameraInitialXLookDownAngle = -20;
export const cameraRotation = 0.00015;
export const cameraMinFOV = 35;
export const cameraMaxFOV = 55;
export const cameraMinScreenWidthBound = 480;
export const cameraMaxScreenWidthBound = 1920;

export const cameraNear = 1;
export const cameraFar = 400000;
export const cameraX = 0;
export const cameraY = 20000;
export const cameraZ = 0;

export const planeDefinition = 200;
export const planeSize = 1245000;
export const meshColor = "grey";

export const fogColor = "black";
export const fogNear = 1;
export const fogFar = 350000;

export const rendererColor = "black";
export const rendererPosition = "absolute";
export const rendererTop = "0";
export const rendererZIndex = "1";
export const rendererWidth = "100%";
export const rendererHeight = "100%";
